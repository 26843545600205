import { get, flowRight } from 'lodash';
import { connect } from '../../common/components/runtime-context';

import withHocName from '../../app/hoc/with-hoc-name';
import { CARD_BORDER_WIDTH_PATH } from '../constants/wix-params';
import { getAppSettings } from '../../common/store/app-settings/app-settings-selectors';

const DEFAULT_WIDTH = 1;

const getBorderWidth = state => {
  return get(getAppSettings(state), CARD_BORDER_WIDTH_PATH, DEFAULT_WIDTH);
};

const mapRuntimeToProps = state => ({
  borderWidth: getBorderWidth(state),
});

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
  withHocName('WithCardBorderWidth')
);
