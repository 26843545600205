import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import TimeAgo from '../../../app/components/time-ago';
import PostLink from '../post-link';
import PostItemCover from '../post-item-cover';
import PostItemFooter from '../post-item-footer';
import styles from './recent-posts-item.scss';

const RecentPostsItem = ({
  post,
  titleFontClassName,
  titleFontColorClassName,
  withCover,
  isPostNumbersEnabled,
  handleLikeClick,
  forPublicUser,
  isViewsCountEnabled,
}) => {
  const renderTitle = (
    <div
      className={classNames(titleFontClassName, titleFontColorClassName, styles.titleWrapper)}
      data-hook="recent-post__title"
    >
      <PostLink className={classNames(styles.title, 'forum-link-hover-color')} post={post}>
        <h4
          style={{
            fontSize:
              '14px' /* this is for bug fix in CSS modules class name generator where style is lost */,
          }}
          className={styles.headingWrapper}
        >
          {post.title}
        </h4>
      </PostLink>
    </div>
  );

  const renderHeader = (
    <div className={classNames(styles.headerWrapper, titleFontClassName, titleFontColorClassName)}>
      <div className={styles.name}>{get(post, 'owner.name')}</div>
      <TimeAgo className={styles.date} time={post.createdDate} />
    </div>
  );

  const renderFooter = (
    <PostItemFooter
      {...{
        post,
        handleLikeClick,
        titleFontClassName,
        titleFontColorClassName,
        forPublicUser,
        isViewsCountEnabled,
      }}
    />
  );

  return (
    <div className={classNames(styles.main)}>
      <div className={styles.contentRow}>
        {withCover && (
          <div className={styles.cover}>
            <PostItemCover post={post} type={{ fixedHeight: 70, fixedWidth: 70 }} />
          </div>
        )}
        <div className={styles.content}>
          {renderHeader}
          {renderTitle}
        </div>
      </div>
      <div>{isPostNumbersEnabled && renderFooter}</div>
    </div>
  );
};

RecentPostsItem.propTypes = {
  post: PropTypes.object.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  titleFontColorClassName: PropTypes.string.isRequired,
  navigation: PropTypes.object,
  withCover: PropTypes.bool.isRequired,
  isPostNumbersEnabled: PropTypes.bool.isRequired,
  handleLikeClick: PropTypes.func.isRequired,
  forPublicUser: PropTypes.func.isRequired,
  isViewsCountEnabled: PropTypes.bool.isRequired,
};

export default RecentPostsItem;
