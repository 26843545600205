import React from 'react';
// import { createStateReceiver } from './state-receiver';
import hoistNonReactStatics from 'hoist-non-react-statics';
import getDisplayName from 'react-display-name';

export function withStateReceiver(WrappedComponent) {
  class WithStateReceiver extends React.Component {
    static displayName = `withStateReceiver(${getDisplayName(WrappedComponent)})`;

    // constructor(props) {
    //   super(props);
    //   this.receiver = createStateReceiver();
    // }

    render() {
      // return <WrappedComponent {...this.props} state={this.receiver(this.props.state)} />;
      return <WrappedComponent {...this.props} />;
    }
  }

  hoistNonReactStatics(WithStateReceiver, WrappedComponent);

  return WithStateReceiver;
}
